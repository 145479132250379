import Vue from 'vue'
// 页面混入滚动到固定位置,给animate效果
export const scrollAnimate = {
  data () {
    return {
     refArray: {}
    }
  },
  created () {
    this.mixinsSetRefArray(this.refName,this.list.length)
   },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    mixinsSetRefArray(name,len){
      for(let i=0;i<len;i++){
        this.refArray[name+i]=false
      }
    },
    handleScroll () { // 实现当滚动到指定位置，触发动画=
      for(let key in this.refArray){
        this.checkPosition(key, 20, (isHere) => {
          const tempObj={}
          tempObj[key]=isHere
          this.refArray=Object.assign({},this.refArray,tempObj)
        })
      }
    },
    checkPosition (div, offset, callback) {
      let dom = this.$refs[div][0] // 等同于document.querySelector('.earth') true
      if(dom){
        var a,b,c;
        a = this.getPositionTop(dom)
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        if( b + c > a ) {
          callback && callback(true)
        }
      }
    },
    getPositionTop(node) {
      var top = node.offsetTop
      var parent = node.offsetParent
      while (parent != null) {
        top += parent.offsetTop
        parent = parent.offsetParent
      }
      return top
    },
  }
}

export const mixinsScrollErrorPosition = {
  methods: {
    // el-from 表格验证 滚动到固定地方
    mixinsScrollView() {
      setTimeout(() => {
        const isError = document.getElementsByClassName('is-error')
        try {
          isError[0].querySelector('input').scrollIntoView({
            // 滚动到指定节点
            // 值有start,center,end，nearest，当前显示在视图区域中间
            block: 'center',
            // 值有auto、instant,smooth，缓动动画（当前是慢速的）
            behavior: 'smooth'
          })
        } catch (error) {}
      }, 1)
    }
  }
}
